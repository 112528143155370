import axios from "axios";

export const getUserService = async (id) => {
    const { data } = await axios.get(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/users/user/${id}`);
    return data;
}

export const getUserWithEmailService = async (email) => {
    const { data } = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/users/user-email`, {email});
    return data;
}

export const addUserService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/users/newUser`, obj);
    return data;
}

export const editUserService = async (obj, id) => {
    const { data } = await axios.put(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/users/editUser/${id}`, obj);
    return data;
}

export const updateProfilePhotoService = async (obj, id) => {

    const { data } = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/users/updateProfilePhotoUser/${id}`, obj);

    return data;
}

export const addIntegrationDriveService = async (obj, id) => {

    const { data } = await axios.put(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/users/addIntegrationUser/${id}`, obj);

    return data;
}

export const updateIndexDriveService = async (obj, id) => {

    const { data } = await axios.put(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/users/updateIndexUser/${id}`, obj);

    return data;
}