import {useEffect, useMemo, useState} from "react";
import {Stack} from "@fluentui/react";

import styles from './answer.module.css'

import {parseAnswerToHtml, parseFollowUp, parseInsightsToHtml} from "./answer-parser";
import {AnswerIcon} from "./answer-icon";
import {getCitationFilePathHR} from "../../../../../services/agent-hr.service";
import {connect} from "react-redux";
import {SendEmailButton} from "../send-email-button/send-email-button";
import {Chip, IconButton} from "@mui/material";
import {
    Assistant,
    BarChart,
    PictureAsPdf,
    ThumbDownAlt,
    ThumbDownAltOutlined,
    ThumbUpAlt,
    ThumbUpAltOutlined
} from "@mui/icons-material";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import './answer.css';
import PbiQaIndex from "../pbi-qa/pbi-qa-index";
import {getY} from "../../helpers/pdf";
import AnswerLoading from "./answer-loading";
import FollowupLoading from "./followup-loading";
import AnswerInfo from "./answer-info";

const Answer = (props) => {
    // console.log(props.makeApiRequest)
    // console.log("ANS", document.querySelector(".answer-container"));
    const {
        answer,
        isSelected,
        onCitationClicked,
        onThoughtProcessClicked,
        onSupportingContentClicked,
        onFollowupQuestionClicked,
        showFollowupQuestions,
        newFollowup,
        index,
        isLoadingInsight,
        isLoadingFollowup,
        toggleActionPlan,
        updateRateAnswer
    } = props

    /*const [width, setWidth] = useState(0);
    useEffect(() => {
        const content = document.querySelector(".answer-container");
        console.log("content", content.clientWidth, content.clientHeight);
        setWidth(content?.clientWidth);
    },[])

    useEffect(() => {
        console.log("WIDTH", width, showFollowupQuestions);
        if (!showFollowupQuestions) {
            const container = document.querySelector(".answer-container");
            container.styles.width = width;
            console.log("WIDTH SW", width);
        }
    }, showFollowupQuestions);*/
    const parsedAnswer = useMemo(
        () => parseAnswerToHtml(answer.answer, onCitationClicked, props.user, props.containerName, answer, props.chatTheme),
        [answer]
    )

    const followupQuestions = parseFollowUp(answer);
    // const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    const sanitizedAnswerHtml = parsedAnswer.answerHtml;
    let parsedInsights = ''
    if(answer.insight)
        parsedInsights=parseInsightsToHtml(answer.insight)

    const printPDF = async () => {
        const doc = new jsPDF();
        doc.setFontSize(10);
        const div = document.createElement('div');
        div.style.whiteSpace = "pre-line";
        div.innerHTML = sanitizedAnswerHtml;

        const table = div.querySelector('table');
        if (table) {
            const contentTable = div.querySelector('.table-content');
            let prevElements = [];
            let currentElement = contentTable.previousElementSibling;
            while (currentElement !== null) {
                prevElements.unshift(currentElement);
                currentElement = currentElement.previousElementSibling;
            }

            let nextElements = [];
            currentElement = contentTable.nextElementSibling;
            while (currentElement !== null) {
                nextElements.unshift(currentElement);
                currentElement = currentElement.nextElementSibling;
            }

            //join all in a pdf
            // await doc.html(prevElements[0],{margin: [10, 10, 10, 10], autoPaging: 'text', x: 10, y: 10, width: 150, windowWidth: 500});

            let currentY = getY(doc, prevElements.length > 0 ? prevElements[0].outerHTML : '', 15) + 10;

            doc.autoTable({html: table, startY: currentY + 10});

            currentY = getY(doc, nextElements.length > 0 ? nextElements[0].outerHTML : '', doc.autoTable.previous.finalY + 10) + 10;
            // await doc.html(nextElements[0],{margin: [10, 10, 10, 10], autoPaging: 'text', width: 150, windowWidth: 500,x: 10, y: doc.autoTable.previous.finalY + 10});
            doc.save('document');
        } else {
            doc.html(div, {
                async callback(doc) {
                    await doc.save('document');
                    div.classList.remove('page-size');
                },
                margin: [10, 10, 10, 10],
                autoPaging: 'text',
                x: 15,
                y: 15,
                width: 150, //target width in the PDF document
                windowWidth: 500 //window width in CSS pixels
            });
        }
    }

    return (
        <div>
            <Stack style={{
                background: props.chatTheme.answerBg,
                color: props.chatTheme.answerText,
            }}
                   className={`${styles.answerContainer} ${isSelected && styles.selected}`}
                   id={`answer-container-${index}`}
                   verticalAlign="space-between">
                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between">
                        <AnswerIcon chatTheme={props.chatTheme}/>
                        <div>
                            <SendEmailButton answer={answer} index={index} content={sanitizedAnswerHtml}
                                             citations={parsedAnswer.citations} user={props.user}
                                             chatTheme={props.chatTheme}/>
                            <IconButton
                                title="Show thought process"
                                ariaLabel="Show thought process"
                                onClick={() => printPDF()}>
                                <PictureAsPdf style={{
                                    color: props.chatTheme.iconColor
                                }}/>
                            </IconButton>
                            {/*<IconButton
                            title="Show QA report"
                            index={props.index}
                            ariaLabel="Show QA report"
                            onClick={() => props.onSetShowQa()}>
                            <BarChart/>
                        </IconButton>*/}
                        </div>
                        {/*<div>
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.thoughts}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.data_points.length}
                        />
                    </div>*/}
                    </Stack>
                </Stack.Item>

                {/*answer.isPbi ?
                <Stack.Item grow>
                    <div id={`content-${props.index}`} className={`${styles.answerText} ${answer.finish ? '' : styles.answerTextCursor}`}>
                        <PbiQaIndex answers={props.answers}
                                    index={index}
                                    showFollowupQuestions={showFollowupQuestions}
                                    query={answer.qnaQuery}
                        question={props.question}
                        setClose={props.setClose}/>
                    </div>
                </Stack.Item> :*/}

                {/* Final message div */}
                <Stack.Item grow>
                    <div id={`content-${props.index}`}
                         className={`${styles.answerText} ${answer.finish ? '' : styles.answerTextCursor}`}
                         dangerouslySetInnerHTML={{__html: sanitizedAnswerHtml}}/>

                </Stack.Item>

                {!!parsedAnswer.citations.length && answer.finish && (
                    <Stack.Item>
                        <Stack horizontal wrap tokens={{childrenGap: 5}}>
                            <span className={styles.citationLearnMore}>Citations:</span>
                            {parsedAnswer.citations.map((x, i) => {
                                const path = getCitationFilePathHR(x, props.user.id, answer.containerName);
                                if(answer.data_points.some(item => item.title === x))
                                return (
                                    <span>
                                    <a key={i} className={styles.citation} title={x}
                                       onClick={() => onCitationClicked(path, x)}>
                                        {`${++i}. ${x}`}
                                    </a>
                                        {/*<a key={-i} className={styles.citation} title={'See more...'}
                                    onClick={() => props.makeApiRequest('Please answer my previous question in more detail.')}>
                                        {'See more...'}
                                    </a>}
                                    <br></br>*/}
                                </span>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                )}
                <Stack.Item grow>
                    <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                        {/** ANSWER-INFO**/}
                        {answer.statistics && <AnswerInfo statistics={answer.statistics}/>}

                        {/** RATE ICONS*/}
                        {/* TODO enable for all users? */}
                        {props.user?.copilotAccess?.rateAnswer && answer.finish && answer.rate === undefined &&
                            <div className="">
                                <ThumbUpAltOutlined onClick={() => updateRateAnswer(true)} style={{cursor: 'pointer', fontSize: 15}} className="me-2"/>
                                <ThumbDownAltOutlined onClick={() => updateRateAnswer(false)} style={{cursor: 'pointer', fontSize: 15}}/>
                            </div>}
                    </Stack>
                </Stack.Item>
            </Stack>

            {/** INSIGHTS**/}
            {(answer.insight || isLoadingInsight) &&
                <Stack.Item grow style={{
                    background: props.chatTheme.answerBg,
                    color: props.chatTheme.answerText,
                }}
                            className={styles.insights}>

                    {!answer.insight && <AnswerLoading/>}

                    {answer.insight && <h4 className="text-primary"><b>INSIGHTS</b></h4>}
                    <div
                        className={`${styles.answerText} ${styles.insightText} ${answer.finishInsight ? '' : styles.answerTextCursor}`}
                        dangerouslySetInnerHTML={{__html: parsedInsights}}>
                    </div>
                    <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                        {/** ANSWER-INFO**/}
                        {answer.insightObject && <AnswerInfo statistics={answer.insightObject}/>}
                    </Stack>
                    {/**add button*/}
                    {false && answer.insight && answer.finishInsight && <div className="w-100">
                        <Chip icon={<Assistant style={{
                             color: props.chatTheme.followupText
                        }}/>} label="Action Plan" style={{
                            cursor: "pointer",
                            color: props.chatTheme.followupText,
                            background: props.chatTheme.followupBg
                        }} onClick={() => toggleActionPlan(answer)}/>
                    </div>}
                </Stack.Item>}

            {/** FOLLOWUP QUUESTIONS**/}
            {isLoadingFollowup && <FollowupLoading/>}
            {!!followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item className="mt-3">
                    <Stack horizontal wrap
                           className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`}
                           tokens={{childrenGap: 6}}>
                        <div className="w-100">
                            <Chip icon={<Assistant style={{
                                color: props.chatTheme.followupText
                            }}/>} label="Get More relevant response" style={{
                                cursor: "pointer",
                                color: props.chatTheme.followupText,
                                background: props.chatTheme.followupBg
                            }} onClick={newFollowup}/>
                        </div>
                        {/*<span className={styles.followupQuestionLearnMore}>What do you want to ask now? </span>*/}
                        {followupQuestions.map((x, i) => {
                            return (
                                <a key={i} style={{
                                    color: props.chatTheme.followupBg,
                                    borderColor: props.chatTheme.followupBg
                                }} className={styles.followupQuestion} title={x}
                                   onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
        </div>
    );
};


const mapStateToProps = (state) => {
    console.log('state app:', state)
    return {
        user: state.users.user,
        containerName: state.agentHrs.container,
        chatTheme: state.agentHrs.chatTheme
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Answer)
