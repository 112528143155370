import UserActionTypes from "./users.types";

const INITIAL_STATE = {
    user: null,
    status: 'initial',
    statusCreate: 'initial',
    country: null,
};

const usersReducer = (state = INITIAL_STATE, action) => {
    console.log("PL", action);
    switch (action.type) {
        case UserActionTypes.CHANGE_STATUS_USER:
            return {...state, status: action.payload};
        case UserActionTypes.CHANGE_CREATE_STATUS:
            return {...state, statusCreate: action.payload};
        case UserActionTypes.GET_USER:
            return {...state, user: action.payload};
        case UserActionTypes.ADD_USER:
            return {...state, user: action.payload};
        case UserActionTypes.EDIT_USER:
            return {...state, user: action.payload};
        case UserActionTypes.UPDATE_PROFILE_PHOTO:
            return {...state, user: action.payload};
        case UserActionTypes.UPDATE_COUNTRY:
            return {...state, country: action.payload};
        default:
            return state;
    }
};


export default usersReducer;
