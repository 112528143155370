import {
    addUser,
    changeCreateUserStatus,
    changeUserStatus,
    editUser,
    getUser,
    updateProfilePhoto,
} from "./users.actions";
import {
    addIntegrationDriveService,
    addUserService,
    editUserService,
    getUserService,
    getUserWithEmailService, updateIndexDriveService,
    updateProfilePhotoService,
} from "../../services/user.service";
import {deleteOneDriveAccountService} from "../../services/drive-hr.service";
import {toastr} from "react-redux-toastr";

export const getUserThunk = (id) => {
    return async (dispatch) => {
        dispatch(changeUserStatus('loading'));
        try {
            const data = await getUserService(id);
            console.log("data get user", data);
            if (!data.error) {
                dispatch(getUser(data));
                dispatch(changeUserStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

/** get user with email **/
export const getUserWithEmailThunk = (email) => {
    return async (dispatch) => {
        dispatch(changeUserStatus('loading'));
        console.log("LOADING USER");
        try {
            const data = await getUserWithEmailService(email);
            console.log("data get user EMAIL", data);
            if (!data.error) {
                dispatch(getUser(data));
                dispatch(changeUserStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};


export const addUserThunk = (obj) => {
    return async (dispatch) => {
        dispatch(changeCreateUserStatus('loading'));
        try {
            await addUserService(obj);
            const data = await getUserWithEmailService(obj.email);
            console.log("data", data);
            if (!data.error){
                dispatch(addUser(data));
                dispatch(changeCreateUserStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const editUserThunk = (obj, id) => {
    return async (dispatch) => {
        dispatch(changeUserStatus('loading'));
        try {
            await editUserService(obj, id);
            // const data = await getUserService(id);
            const data = await getUserWithEmailService(obj.email);
            console.log("data edit", data);
            if (!data.error) {
                dispatch(editUser(data));
                dispatch(changeUserStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const updateDriveUserThunk = (obj, id) => {
    return async (dispatch) => {
        dispatch(changeUserStatus('loading'));
        try {
            const user = await addIntegrationDriveService(obj, id);
            const data = await getUserWithEmailService(user.email);
            if (!data.error) {
                dispatch(editUser(data));
                dispatch(changeUserStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const deleteDriveUserThunk = (token, masterEmail, tenantId, uniqueId) => {
    return async (dispatch) => {
        dispatch(changeUserStatus('loading'));
        try {
            const data = await deleteOneDriveAccountService(token, masterEmail, tenantId,uniqueId);
            if (!data.error) {
                toastr.success(`Microsoft Onedrive account deleted successfully`);
                dispatch(editUser(data));
                dispatch(changeUserStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const updateIndexUserThunk = (obj, id) => {
    return async (dispatch) => {
        dispatch(changeUserStatus('loading'));
        try {
            const user = await updateIndexDriveService(obj, id);
            const data = await getUserWithEmailService(user.email);
            if (!data.error) {
                dispatch(editUser(data));
                dispatch(changeUserStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

export const updateProfilePhotoThunk = (obj, id) => {
    return async (dispatch) => {
        dispatch(changeUserStatus('loading'));
        try {
            console.log("LOADING update");
            await updateProfilePhotoService(obj, id);
            const data = await getUserService(id);
            console.log("data update profile photo", data);
            if (!data.error) {
                dispatch(updateProfilePhoto(data));
                dispatch(changeUserStatus('loaded'));
            }
        } catch (error) {
            console.error(error);
        }
    };
};

