import {List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {Done} from "@mui/icons-material";
import DialogContact from "./dialog-contact/dialog-contact";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

const BillingContact = (props) => {
    console.log('im hereeee!!!!');
    const [openForm, setOpenForm] = useState(false);
    const [totalPrice, setTotalPrice] = useState(499);
    const navigate = useNavigate();
    const {t} = useTranslation();
    return (
        <div>
            <div className="card card-area-animation text-center">
                <div className="card-body">
                    <Typography variant="h4" className="my-4">
                                                        <span className="price">
                                                            <small>Starting at </small>${totalPrice}
                                                        </span>
                        <h5 className="d-inline-block"><small>/{"month"}</small></h5>
                    </Typography>
                    <p className="text-info my-4"><b>Custom Plan & Price</b></p>
                    {/*<div className="w-100 my-3">
                                                    <PrettoSlider
                                                        aria-label="Price"
                                                        defaultValue={1}
                                                        getAriaValueText={(value) => `${value} month(s)`}
                                                        valueLabelDisplay="on"
                                                        onChange={(e) => this.setState({secondTotalPrice: e.target.value * this.state.secondPrice})}
                                                        step={1}
                                                        marks
                                                        min={1}
                                                        max={12}
                                                    />
                                                </div>*/}
                    <div className="text-center my-3">
                        {/*<button onClick={(e) => this.purchaseSubscription(e)} className="btn btn-primary mr-2">{"Buy Now"}</button>*/}
                        <button onClick={() => navigate('/contact-sales')}
                                className="btn btn-block btn-info mr-2">{"Contact Sales"}</button>
                    </div>
                    <p className="text-center text-secondary">Get a Custom Plan</p>
                    <div className="text-start">
                        <List dense={false}>
                            {['Collaborate with your team.', 'Collaboration Platforms Integration (Slack, Teams, Google, etc).', 'Data Lake Integration.', 'API access.', 'Personalized onboarding & training.', 'Dedicated account manager.'].map((item) => (
                                <ListItem>
                                    <ListItemIcon>
                                        <Done className="text-success" />
                                    </ListItemIcon>
                                    <ListItemText primary={item}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </div>
            </div>
            {/*openForm ? <DialogContact open={openForm}
                                                  t={t}
                                                  onClose={setOpenForm} /> : null*/}
        </div>
    )
}

export default BillingContact;
