import {useTranslation} from "react-i18next";
import Billing from "./billing-stripe-index";
import {useState} from "react";
import {useAwsGuard} from "../../guards/aws-guard";
import BillingAwsIndex from "./billing-aws-index";

const BillingIndex = () => {
    const { t } = useTranslation()
    const [hasAws, setHasAws] = useState(useAwsGuard())
    return (hasAws ? <BillingAwsIndex/> : <Billing t={t}/>)
}

export default BillingIndex;
