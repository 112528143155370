import React, { useEffect, useState, forwardRef } from 'react';
import { Bar, Line, Pie, Doughnut, Radar, PolarArea } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, ArcElement, RadialLinearScale } from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    RadialLinearScale
);

const ChartGeneratorData = async (answer) => {

    try {
        if (!answer || !answer.sql_json || answer.sql_json.length===0) {
            console.log('No SQL JSON data provided');
            return;
        }

        let parsedData = answer.sql_json;

        if (parsedData.length < 2) {
            console.log('The JSON must contain more than one object.');
            return;
        }

        for (let item of parsedData) {
            const keys = Object.keys(item);
            if (keys.length < 2 || typeof item[keys[1]] !== 'number') {
                console.log('Each object must have at least two properties and the second must be numerical.');
                return;
            }
        }

        const limitedData = parsedData.slice(0, 5);
        
        const response = await fetch(`${process.env.REACT_APP_BACK_HR_AGENT}/generate-json-chart`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ data: limitedData }),
        });
        
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        let res = await response.json();
        
        return res;
    } catch (err) {
        console.error('Error occurred in backend:', err);
    } 
};

const ChartGenerator = forwardRef(({ answer}, ref) => {
    const [chartData, setChartData] = useState(null);
    const [chartType, setChartType] = useState('');
    const [labels, setLabels] = useState('');
    const [propertyNames, setPropertyNames] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const generateChart = async () => {
            setLoading(true);
            setError('');

            try {
                let parsedData = answer.sql_json;

                const result = answer;                
                const lab = [];
                const num = [];
                const propertyName = result.properties.map(property => property);
                setPropertyNames(propertyName);
                const type = result.typeChart.map(type => type);

                var types = type[type.length - 1];
                setChartType(types);

                for (let i = 0; i < parsedData.length; i++) {
                    lab.push(parsedData[i][result['properties'][0]]);
                    num.push(parsedData[i][result['properties'][1]]);
                }
                setLabels(lab);
                const newChartData = getChartData(types, lab, num, backgroundColors, borderColors, propertyName);
                setChartData(newChartData);

            } catch (err) {
                console.error('Error occurred in backend:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        generateChart();
        answer.graphicReady = true;
    }, [answer]);

    const backgroundColors = [
        'rgba(255, 0, 0, 0.7)', 
        'rgba(0, 255, 0, 0.7)', 
        'rgba(0, 0, 255, 0.7)', 
        'rgba(255, 255, 0, 0.7)', 
        'rgba(255, 0, 255, 0.7)', 
        'rgba(0, 255, 255, 0.7)', 
        'rgba(128, 0, 128, 0.7)', 
        'rgba(255, 140, 0, 0.7)',
        'rgba(255, 99, 132, 0.7)',
        'rgba(255, 159, 64, 0.7)',
        'rgba(255, 205, 86, 0.7)',
        'rgba(75, 192, 192, 0.7)',
        'rgba(54, 162, 235, 0.7)',
        'rgba(153, 102, 255, 0.7)',
        'rgba(201, 203, 207, 0.7)'
    ];

    const borderColors = [
        'rgba(255, 0, 0, 0.2)', 
        'rgba(0, 255, 0, 0.2)', 
        'rgba(0, 0, 255, 0.2)', 
        'rgba(255, 255, 0, 0.2)', 
        'rgba(255, 0, 255, 0.2)', 
        'rgba(0, 255, 255, 0.2)', 
        'rgba(128, 0, 128, 0.2)', 
        'rgba(255, 140, 0, 0.2)',
        'rgb(255, 99, 132, 0.2)',
        'rgb(255, 159, 64, 0.2)',
        'rgb(255, 205, 86, 0.2)',
        'rgb(75, 192, 192, 0.2)',
        'rgb(54, 162, 235, 0.2)',
        'rgb(153, 102, 255, 0.2)',
        'rgb(201, 203, 207, 0.2)'
    ];

    const getChartData = (chartType, labels, data, backgroundColors, borderColors, propertyNames) => {
        switch (chartType) {
        case 'Bar':
            return {
                labels: labels,
                datasets: [{
                    label: propertyNames[1],
                    data: data,
                    backgroundColor: backgroundColors.slice(0, data.length),
                    borderColor: borderColors.slice(0, data.length),
                    borderWidth: 1
                }]
            };
        case 'Line':
            return {
                labels: labels,
                datasets: [{
                    label: propertyNames[1],
                    data: data,
                    fill: false,
                    borderColor: borderColors[1],
                    backgroundColor: backgroundColors[0],
                    borderWidth: 2,
                    tension: 0.4
                }]
            };
        case 'Pie':
            return {
                labels: labels,
                datasets: [{
                    label: propertyNames[1],
                    data: data,
                    backgroundColor: backgroundColors.slice(0, data.length),
                    hoverOffset: 9
                }]
            };
        case 'Doughnut':
            return {
                labels: labels,
                datasets: [{
                    label: propertyNames[1],
                    data: data,
                    backgroundColor: backgroundColors.slice(0, data.length),
                    hoverOffset: 9
                }]
            };
        case 'PolarArea':
            return {
                labels: labels,
                datasets: [{
                    label: propertyNames[1],
                    data: data,
                    backgroundColor: backgroundColors.slice(0, data.length),
                    borderColor: borderColors.slice(0, data.length),
                    borderWidth: 1
                }]
            };
        case 'Radar':
            return {
                labels: labels,
                datasets: [{
                    label: propertyNames[1],
                    data: data,
                    backgroundColor: backgroundColors[0],
                    borderColor: borderColors[3],
                    pointBackgroundColor: 'rgb(255, 99, 132)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgb(255, 99, 132)'
                }]
            };
        default:
            return null;
        }
    };
    
    const renderChart = (chartRef) => {
        switch (chartType) {
        case 'Bar':
            return <Bar ref={chartRef} data={chartData} />;
        case 'Line':
            return <Line ref={chartRef} data={chartData} />;
        case 'Pie':
            return <Pie ref={chartRef} data={chartData} />;
        case 'Doughnut':
            return <Doughnut ref={chartRef} data={chartData} />;
        case 'PolarArea':
            return <PolarArea ref={chartRef} data={chartData} />;
        case 'Radar':
            return <Radar ref={chartRef} data={chartData} />;
        default:
            return null;
        }
    };

    return (
        <div className="chart-generator">
            {renderChart(ref)}
        </div>
    );
});

export {ChartGenerator, ChartGeneratorData};

