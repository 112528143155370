import { Example } from './example'
import Lottie from 'react-lottie'
import animationData from './stars.json'

import styles from './example.module.css'
import { connect } from "react-redux";
import {ConnectWithoutContact, Handshake, HelpOutline, PersonSearch, Search, Upload, FolderShared} from "@mui/icons-material";
import {capitalize} from "@mui/material";

const EXAMPLES = [
  {
    text: 'Give me the monthly payroll report of the whole organization',
    value: 'Give me the monthly payroll report of the whole organization',
  },
  {
    text: 'Can you give me a report of performance review of this year? ',
    value: 'Can you give me a report of performance review of this year? ',
  },
  {
    text: "What's the status of the hiring process for the software engineers? ",
    value:
      "What's the status of the hiring process for the software engineers?",
  },
]

const CCS_EXAMPLES = [
  { text: `Who is my <b>Career Coach</b>?`, value: 'Who is my Career Coach?', icon: <PersonSearch/>},
  { text: `How can the <b>Office of Partnerships</b> Career Development help me?`, value: 'How can the Office of Partnerships Career Development help me?', icon: <HelpOutline/> },
  { text: `How can I connect with the <b>Office of Partnerships</b> Career Development?`, value: 'How can I connect with the Office of Partnerships Career Development?', icon: <ConnectWithoutContact/> },
  { text: `How I can <b>create a portfolio</b>?`, value: 'How I can create a portfolio?', icon: <FolderShared/> },
  { text: `How do I search for an <b>Internship</b>?`, value: 'How do I search for an internship?', icon: <Search/> },
  { text: `What are the steps to <b>upload my portfolio</b>?`, value: 'What are the steps to upload my portfolio?', icon: <Upload/> },
  { text: `<b>What Else</b> can you do for me?`, value: 'What Else can you do for me?', icon: <HelpOutline/> }

]

const ExampleList = (props) => {
  const { onExampleClicked, indexName } = props;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    hover: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div style={{
      // color: props.chatTheme.answerText,
      //background: props.chatTheme.answerBg
    }} className={styles.example}>
      <h3>Hi, {capitalize((props.user?.firstName + '!') || '')}</h3>
      <div className={styles.initialTextContainer + ' my-4'}>
        <div style={{ width: '40px', flex: '1' }}>
          <Lottie options={defaultOptions} height={40} width={40} />
        </div>
        <div className={styles.initialText}>
          Hey there! I'm Watson, your friendly, not-so-secret weapon for conquering the career battlefield. Even though I'm an AI-powered assistant, I've got loads of knowledge on internships, portfolios, and how to get you career-ready. So, don't hesitate to ask me anything – I'm here to help!
        </div>
      </div>
      {/*<ul className={styles.examplesNavList}>
        {CCS_EXAMPLES.map((x) => (
            <li>
              <Example text={x.text} value={x.value} onClick={onExampleClicked} />
            </li>))}
      </ul>*/}
      <div className="row">
        {CCS_EXAMPLES.map((x) => (
            <div className="col-6 col-md-4 col-lg-3 px-1 pb-2">
              <Example chatTheme={props.chatTheme} text={x.text} value={x.value} icon={x.icon} onClick={onExampleClicked} />
            </div>))}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    chatTheme: state.agentHrs.chatTheme,
    user: state.users.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(ExampleList)
