import './home.css';
import {CircularProgress} from "@mui/material";
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated} from "@azure/msal-react";
// import {SignInButton} from "../../auth/ui";
import React, {useState} from "react";
import {connect} from "react-redux";
import {loginRequest} from "../../azure_auth/authConfig";
import {SignInButton} from "../../azure_auth/components/SignInButton";
import Dashboard from "../dashboard/dashboard";
import {useTranslation} from "react-i18next";
import RotatingSpinner from "../layouts/RotatingSpinner";

const HomePage = (props) => {

    //const isAuthenticated = useIsAuthenticated();
    const {accounts, instance, inProgress} = useMsal();
    const {t, i18n} = useTranslation();
    const lngs = {
        en: {nativeName: 'English'},
        // ru: {nativeName: 'Русский'},
        es: {nativeName: 'Español'},
        ja: {nativeName: "日本語"},
        // zh: {nativeName: "臺灣話"}
    };

    console.log("ACCOUNTS: ");
    console.log(accounts);
    console.log("SESSION STORAGE");
    console.log(window.sessionStorage);



    const [loginRequired, setLoginRequired] = useState(false);

    async function getUserProfile() {
        // Silently acquires an access token which is then attached to a request for MS Graph data
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: window.sessionStorage,
            })
            .then((response) => {

                console.log("TOKEN ACQUIRED");
                console.log(response);
                // callMsGraph(response.accessToken).then((response) => {
                //   let user = {
                //     lastName: response.surname,
                //     firstName: response.givenName,
                //     email: response.mail,
                //     id: response.id,
                //     tenantId: this.context.accounts[0].tenantId
                //   };


                // });
            }).catch(function (error) {
            console.log("ERROR WHEN ACQUIRING");
            console.log(error);
            // if (error instanceof InteractionRequiredAuthError){
            //   console.log("INTERACTION REQUIRED");
            //   setLoginRequired(true);

            // }
            setLoginRequired(true);
        })
    }

    // getUserProfile();

    return (
        <div>
            <div>
                {Object.keys(lngs).map((lng) => (
                    <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                        {lngs[lng].nativeName}
                    </button>
                ))}
            </div>
            <AuthenticatedTemplate>
                {loginRequired ?
                    <div>
                        <p>Your sessions has expired, please Sign In again.</p>
                    </div> : <Dashboard open={props.open}/>
                }
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                {inProgress !== 'none' ?
                    inProgress === 'login' || inProgress === 'logout' ?
                        <div className="text-center">
                            <p className="text-capitalize">{`${inProgress} in progress...`}</p>
                            <RotatingSpinner/>
                        </div> : null :
                    <div className="row h-100 mt-5">
                        {/* <div className="col-md-12">
                            <p className="fs-lg-1">
                                <b>PBI ProtoType with CI/CD</b>
                            </p>
                            <p className="fs-5">please Sign in</p>
                            <SignInButton variant="outlined"
                                          color="primary"
                                          size="large" className="mt-2"/>

                        </div> */}

                    </div>}
            </UnauthenticatedTemplate>
        </div>
    );

}

// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        // user: state.users.user,
        // lastUser: state.users.lastUser,
        // selectedUser: state.users.selectedAccount
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // getUserSlack: (id) => dispatch(getUserThunk(id)),
        // getUserSlackLastTime: () => dispatch(getUserSlackLastTimeThunk())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
