import React, {Component} from 'react';
import {PowerBIEmbed} from 'powerbi-client-react';
import {models} from 'powerbi-client';
import "./Report.css";
import axios from 'axios';
import {connect} from "react-redux";
import {AuthenticatedTemplate, MsalContext, UnauthenticatedTemplate} from "@azure/msal-react";
// import {MutatingDots} from "react-loader-spinner";
import {CircularProgress} from "@mui/material";
import {toFormData} from "../../helpers/toFormData";
import {loginRequest} from "../../azure_auth/authConfig";
import {apiCall} from "../../apiCall";
import RotatingSpinner from "../layouts/RotatingSpinner";


// let filter = [
//   {
//     $schema: "http://powerbi.com/product/schema#advanced",
//     target: 
//     { table: "public slack_questions", 
//     column: "slack_survey_id" }, 
//     filterType: 0, 
//     logicalOperator: "And", 
//     conditions: [{ operator: "Is", value: 34 }]
//   }];


class ReportPBI extends Component {


    state = {
        id: null,
        config: null,
        loading: true,
        accessToken: null
    }

    static contextType = MsalContext;

    async componentDidMount() {

        console.log("report mount");
        try {

            let res = await apiCall(`${process.env.REACT_APP_DASHBOARD_URL}/api/getEmbedToken`);
            console.log("API CALL", res)

            console.log('PBI', res);

            let config = {
                type: 'report', // Supported types: report, dashboard, tile, visual, and qna.
                id: res.embedUrl[0].reportId,
                embedUrl: res.embedUrl[0].embedUrl,
                accessToken: res.accessToken,
                tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
                settings: {
                    panes: {
                        filters: {
                            expanded: false,
                            visible: false
                        }
                    },
                }
            };

            if (window.innerWidth < window.innerHeight) {
                console.log("MOBILE")
                config.settings.layoutType = models.LayoutType.MobilePortrait
                delete config.settings.customLayout;
            }

            this.setState({config, loading: false});
        } catch (error) {
            console.log(error);
        }
    }

    async getToken2() {
        console.log('Account PBI', this.context.accounts[0]);

        //!!!
        //use OUR tenant ID for this, because it is our app that is trying to call the function, not the user! and our app is in our tenant
        let url = `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}/oauth2/v2.0/token`;

        let obj = {
            grant_type: "client_credentials",
            //!!!
            //you were using wrong credentials! you have to use client id and client secret of the app reg used for pbi-prototype function app! of course it won't let you sign in with a token from another app reg!
            client_id: process.env.REACT_APP_GCLIENT_ID,
            client_secret: process.env.REACT_APP_GCLIENT_SECRET,
            scope: `api://${process.env.REACT_APP_GCLIENT_ID}/.default`
        }

        console.log("OBJECT", obj)
        try {
            //Request and access token
            const form = toFormData(obj);
            let response = await axios.post(url, form);
            console.log('RESPONSEEE', response);
            return response.data.access_token;

        } catch (error) {
            console.log(error);
        }
    }

    async getToken() {
        let token = null;
        const {accounts, instance} = this.context;
        console.log('cONTEXT', accounts, instance);

        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        await instance.acquireTokenSilent(request).then((response) => {
            console.log('response1', response.accessToken);
            // this.setState({accessToken: response.accessToken});
            token = response.accessToken;
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                console.log('response2', response);
                // this.setState({accessToken: response.accessToken});
                token = response.accessToken;

            });
        });
        return token;
    }


    // componentDidMount(){
    //   this.setState
    // }

    setFilters = async () => {

        console.log('Report loaded');
        /*let activePage = await window.report.getActivePage();


        console.log(activePage);
        let filter = await activePage.getFilters();
        // const path = window.location.pathname.split('/')
        //const id = path[path.length - 1]
        // filter[0].conditions[0].value = id;
        console.log(filter);

        console.log(window.location);


        try {
            await activePage.updateFilters(models.FiltersOperations.Replace, [filter[0]]);
            console.log("Page filters were replaced.");
        } catch (error) {
            console.log(error);
        }


        const visuals = await activePage.getVisuals();

        console.log("VISUALS");
        console.log(visuals);


        // Retrieve the target visual.
        let slicer = visuals.filter(function (visual) {
            return visual.type === "slicer";
        })[0];

        // Get the slicer state
        let state = await slicer.getSlicerState();

        console.log(state);

        // state.filters[0].values=[this.props.singleSurvey.questions[0].content];

        await slicer.setSlicerState({filters: [state.filters[0]]});

        console.log(slicer);*/
        const tenantId = this.context.accounts[0].tenantId;

        console.log('TENANTID', tenantId);

        try {

            await window.report.getFilters().then((filters) => {
                console.log(filters);
                let myFilter = filters[0];
                myFilter.values[0] = `${tenantId}`;
                let myFilter2 = filters[1];
                myFilter2.values[0] = `${tenantId}`;
                let myFilter3 = filters[2];
                myFilter3.values[0] = `${tenantId}`;
                let myFilter4 = filters[3];
                myFilter4.values[0] = `${tenantId}`;
                let myFilter5 = filters[4];
                myFilter5.values[0] = `${tenantId}`;
                window.report.setFilters([myFilter, myFilter2, myFilter3, myFilter4, myFilter5])
                // // report.updateFilters(models.FiltersOperations.Replace, [myFilter])
                // .then( console.log("Report filter was added."));
            });
        } catch (errors) {
            console.log(errors);
        }

    }


    render() {

        // console.log(this.props);
        return (
            <div className="my-3">

                <AuthenticatedTemplate>

                    {this.state.loading ?
                        <div className="d-flex justify-content-center">
                            <div className="text-center">
                                <p>Loading...</p>
                                <RotatingSpinner/>
                            </div>
                        </div>
                        :
                        <PowerBIEmbed
                            embedConfig={this.state.config
                            }

                            eventHandlers={new Map([
                                ['loaded', this.setFilters],
                                ['rendered', function () {
                                    console.log('Report rendered');
                                }],
                                ['error', function (event) {
                                    console.log(event.detail);
                                }]
                            ])
                            }

                            cssClassName={
                                "report-style-class"
                            }

                            getEmbeddedComponent={
                                (embeddedReport) => {
                                    window.report = embeddedReport;
                                }
                            }

                        />
                    }

                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>Please sign-in to view this page</UnauthenticatedTemplate>
            </div>
        )
    }

}


// Map state to props;
const mapStateToProps = (state) => {
    console.log("state", state);
    return {
        // allSurveys: state.surveys.allSurveys,
        // singleSurvey: state.surveys.singleSurvey,

    };
};

// Map dispatch to props;
const mapDispatchToProps = (dispatch) => {
    return {
        // getAllSurveysWithScheduleSlack: (teamId) => dispatch(getAllSurveysWithScheduleSlackThunk(teamId)),
        // getSingleSurvey: (id) => dispatch(getSingleSurveysWithQandAThunk(id))

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportPBI);
